* {
  font-family: "Nunito", sans-serif;
  --primary-color: #1d71b7 !important;
  --secondary-color: #039b9c !important;
  --thirty-color: #eeeeee !important;
  box-sizing: border-box;
}

/* start PC */

.PC-medias-element {
  margin: 10px;
  display: inline-block;
  border-radius: 10px;
  border: solid 1px var(--thirty-color);
  padding: 10px;
  cursor: pointer;
  transition: 0.6s;
}
.PC-medias-element:hover {
  background-color: var(--thirty-color);
  opacity: 0.7;
}
.PC-medias-element:active {
  opacity: 0.5;
}

.PC-medias-element > img {
  overflow: hidden;
  height: 100px;
}

/* end PC */

.Count-element {
  padding: 10px 20px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}