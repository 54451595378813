.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%
}

.preview {
  border-radius: 20px;
  margin: 30px;
}

.picture {
  position: relative;
  display: inline-block;
  width: 20%;
  margin: 1em
}

.picture > img {
  width: 100%;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.938);
  border-radius: 50%;
}
